<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">Consider the reaction shown below:</p>
      <p class="mb-4 pl-5">
        <chemical-latex content="A(g) + B(g) <=> 2 C(g)" />
        <stemble-latex :content="'$\\qquad\\qquad\\text{K}_{\\text{p}}=$'" />
        <number-value :value="Kp" />
      </p>

      <p class="mb-4">
        If the reaction is initiated by loading
        <number-value :value="initA" />
        <stemble-latex :content="'$\\text{bar}$'" />
        of
        <chemical-latex content="A" />
        in a reaction vessel with
        <number-value :value="initB" />
        <stemble-latex :content="'$\\text{bar}$'" />
        of
        <chemical-latex content="B" />
        , determine the concentrations of each of the chemical species once the reaction reaches
        equilibrium.
      </p>

      <calculation-input
        v-model="inputs.barA"
        :prepend-text="'$\\ce{P_A}$'"
        :append-text="'$\\text{bar}$'"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.barB"
        :prepend-text="'$\\ce{P_B}$'"
        :append-text="'$\\text{bar}$'"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.barC"
        :prepend-text="'$\\ce{P_C}$'"
        :append-text="'$\\text{bar}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question158',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        barC: null,
        barA: null,
        barB: null,
      },
    };
  },
  computed: {
    Kp() {
      return this.taskState.getValueBySymbol('Kp').content;
    },
    initA() {
      return this.taskState.getValueBySymbol('initA').content;
    },
    initB() {
      return this.taskState.getValueBySymbol('initB').content;
    },
  },
};
</script>
